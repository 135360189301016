import axios from '@/apis/axios';

class User
{
    static async all()
    {
        const response = await axios.get('/api/users');
        return response.data.data;
    }

    static async create(data)
    {
        const response = await axios.post('api/users', data);
        return response.data.data;
    }

    static async delete(id, data)
    {
        const response =  await axios.delete(`api/users/${id}`, data);
        return response;
    }
}

export default User;

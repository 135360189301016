<template>
  <div>
    <!-- <AppBreadcrumb /> -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                variant="primary"
                data-test ="AddUserBtn"
                @click="$emit('addNewUser', 'create')"
              >
                <span class="text-nowrap">
                    Add a User
                  <vector data-name="add" data-class="no-path-fill ml-.5" />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

        <b-table
            ref="refListTable"
            class="position-relative"
            :items="userList"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            busy.sync="isBusy"
        >

            <template  #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <span class="ml-2 cursor-pointer">
                    <b-dropdown size="sm" menu-class="ml-n2 mt-2" dropright variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon"/>
                        </template>

                        <b-dropdown-item
                            data-test="deleteUserTest"
                            @click="dataToDelete(data)"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="TrashIcon" />
                                <span style="padding-top: .1em; padding-left: .5em">Delete</span>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </span>
            </template>
        </b-table>

        <loader v-if="isFetchingData" data-type="Table" />

        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-sm-start
                    "
                >
                    <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                        {{ dataMeta.of }} entries</span
                    >
                </b-col>

                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-sm-end
                    "
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>

                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>

    <confirmation-modal  ref="modal">
        <template slot="body">
            <div>
                <feather-icon class="d-block mx-auto text-warning" icon="AlertCircleIcon" size="90"/>
                <h1 class="text-center text-secondary display-5 mt-2">Are you sure?</h1>
                <p class="text-center mt-2"> You won't be able to revert this! </p>
            </div>
        </template>

        <template slot="footer">
             <b-button size="md" variant="primary" @click="confirmDelete">
               Yes, delete it!
            </b-button>

            <b-button size="md" variant="outline-danger" @click="cancelDelete">
                Cancel
            </b-button>
        </template>
    </confirmation-modal>

  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
// import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import vSelect from "vue-select";
import { ref } from '@vue/composition-api';
import useTableHandler from '../useTableHandler';
import ConfirmationModal from '@/views/components/utils/ConfirmationModal.vue';


export default {

    components: {
        // AppBreadcrumb,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        ConfirmationModal,

        vSelect,
    },

    props:{
        userList:{
            required:true,
            type:Array
        },

        isFetchingData: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            toDelete:null,
        };
    },

    methods:{

        dataToDelete(data){
            this.$refs.modal.$refs.confirmationModal.show();
            this.toDelete= data;
        },

        confirmDelete(){
            this.$emit('removeUser', this.toDelete);
            this.toDelete = null;
            this.$refs.modal.$refs.confirmationModal.hide();
        },

        cancelDelete(){
            this.toDelete = null;
            this.$refs.modal.$refs.confirmationModal.hide();
        }
    },

    setup() {
        const isBusy = true;
        const isLoading = ref(true);

        const {
            perPage,
            currentPage,
            totalRows,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,

            // UI
            resolveShareTypeRoleVariant,
            resolveShareTypeRoleIcon,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useTableHandler(isLoading);


        const tableColumns = [
            {key: 'index', label: '#'},
            { key: 'name', label: 'Name', sortable: true },
            { key: 'email', label: 'Email', sortable: true },
            { key: 'actions', label: 'ACTION', thClass: 'text-right', tdClass: 'text-right' },
        ];

        return {
            tableColumns,
            perPage,
            currentPage,
            totalRows,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,

            // UI
            resolveShareTypeRoleVariant,
            resolveShareTypeRoleIcon,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,

            // data
            isBusy,
            isLoading,
        };

    },
};
</script>

<style scoped>

</style>

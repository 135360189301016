
import { ref, watch } from '@vue/composition-api';

export default function useUserHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const userLocal = ref(JSON.parse(JSON.stringify(props.user.value)));

    const resetUserLocal = () => {
        userLocal.value = JSON.parse(JSON.stringify(props.user.value));
    };

    watch(props.user, () => {
        resetUserLocal();
    });

    const onSubmit = () => {
        const user = JSON.parse(JSON.stringify(userLocal));
        emit('add-user', user.value);

        // Close sidebar
        emit('update:is-user-handler-sidebar-active', false);
    };

    const orderOptions= ref([]);

    return {
        userLocal,
        resetUserLocal,
        orderOptions,

        // UI
        onSubmit,
    };
}

<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="isUserHandlerSidebarActive"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="
                (val) => $emit('update:is-user-handler-sidebar-active', val)
            "
            @hidden="clearForm"
        >
            <template #default="{ hide }">
                <!-- Header -->
                <div
                    class="
                        d-flex
                        justify-content-between
                        align-items-center
                        content-sidebar-header
                        px-2
                        py-1
                        side-bar-header
                    "
                >
                    <h3 class="mb-0">
                        Add User
                    </h3>

                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>

                <!-- Body -->
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                    <!-- Form -->
                    <b-form
                        class="p-2"
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetForm"
                    >
                        <!-- Title -->
                        <validation-provider
                            #default="validationContext"
                            name="user"
                            rules="required"
                        >
                            <b-form-group label="User Name" label-for="user">
                                <b-form-input
                                    id="user"
                                    v-model="userLocal.name"
                                    data-test ="user-name"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter User Name"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                                #default="validationContext"
                                name="email address"
                                rules="required|email"
                        >
                            <b-form-group label="Email Address">
                                <b-form-input
                                        v-model="userLocal.email"
                                        placeholder="Enter user email address"
                                        autofocus
                                        :state="
                                        getValidationState(validationContext)
                                    "
                                        trim
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>


                        <!-- Form Actions -->
                        <div class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="
                                    mr-1
                                    mt-1
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                                type="submit"
                                >
                                Add

                                <img
                                    :src="'/illustrations/add-circle.svg'"
                                    class="ml-1"
                                    alt="add-logo"
                                />
                            </b-button>

                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                class="
                                mt-1
                                d-flex
                                justify-content-center
                                align-items-center
                                "
                                variant="outline-primary"
                                @click="$emit('update:is-user-handler-sidebar-active', false)"
                                >Cancel
                                <img
                                    :src="'/illustrations/cancel-circle.svg'"
                                    class="float-right ml-1"
                                    alt="cancel-logo"
                                />
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import useUserHandler from "./useUserHandler";

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isUserHandlerSidebarActive",
        event: "update:is-user-handler-sidebar-active",
    },
    props: {
        isUserHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },

        user: {
            type: Object,
            required: true,
        },

        clearUserData: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            required,
            email,
            datePickerConfig: {
                altInput: true,
                altFormat   : "d M Y",
                dateFormat  : "Y-m-d",
            },
        };
    },

    setup(props, { emit }) {
        const {
            userLocal,
            orderOptions,
            // UI
            onSubmit,
        } = useUserHandler(toRefs(props), emit);

        const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(userLocal, props.clearUserData);


        return {
            // Add New
            userLocal,
            orderOptions,
            onSubmit,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,
        };
    },
};
</script>

<style scope>
.side-bar-header{
   background: #f6f6f6 !important;
 }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

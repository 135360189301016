<template>
    <div>

        <table-and-header
                ref="table"
                :user-list= "users"
                :is-fetching-data= "isFetchingData"
                @addNewUser = "handleUserCreate"
                @removeUser="removeUser"
        />


        <create-or-edit
                v-model="isUserHandlerSidebarActive"
                :user="user"
                :clear-user-data="clearUserData"
                @add-user="addUser"
        />

    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { useRouter } from '@core/utils/utils';
import { computed, ref, watch } from '@vue/composition-api';
import CreateOrEdit from './CreateOrEdit.vue';
import TableAndHeader from './TableAndHeader.vue';
import { errorToast, successToast } from '@/views/helpers/toast';
import { NO_CAMPAIGN, SOMETHING_WENT_WRONG } from '@/constants/errors';
import User from '@/models/User';
import store from '@/store';

export default {
    components: {
        CreateOrEdit,
        TableAndHeader,
    },


    setup() {
        const isBusy = true;
        const isLoading = ref(true);
        const users = ref([]);
        const isFetchingData = ref(false);

        const { route } = useRouter();
        const routeParams = computed(() => route.value.params);

        watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
            fetchUsers();

        });

        const isUserHandlerSidebarActive = ref(false);

        const userBluePrint = {
            id: undefined,
            name: undefined,
            email: undefined,
        };

        const user = ref(JSON.parse(JSON.stringify(userBluePrint)));

        const setUser = dataUser => ({
            id: dataUser.id,
            name: dataUser.name,
            email: dataUser.email,
        });

        const fetchUsers = async() => {
            isFetchingData.value = true;
            users.value = [];

            const userList = await User.all();

            userList.forEach(dataUser => {
                const data = setUser(dataUser);
                users.value.push(data);
            });

            isFetchingData.value = false;
        };
        fetchUsers();

        const clearUserData = () => {
            user.value = JSON.parse(JSON.stringify(userBluePrint));
        };

        const addUser = newUser => {
            const campaign = store.getters['auth/campaign'];
            if (!campaign)
            {
                errorToast(NO_CAMPAIGN);
                return;
            }
            User.create(newUser)
                .then( payload => {
                    // eslint-disable-next-line no-use-before-define
                    const data = setUser(payload);

                    users.value.push(data);
                    successToast("New user has been created successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to store user.",
                        e
                    );
                });
        };

        const removeUser = userToDelete => {
            const authUser = store.getters['auth/user'];
            if (authUser.email === userToDelete.item.email)
            {
                errorToast("You cannot delete your own account.");
                return;
            }

            User.delete(userToDelete.item.id, userToDelete.item)
                .then(() => {
                    users.value = users.value.filter( exitingUser =>
                    // eslint-disable-next-line no-use-before-define
                        exitingUser.id !== userToDelete.item.id
                    );
                    successToast("Record has been deleted successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to delete user.",
                        e
                    );
                });
        };


        const handleUserCreate = () => {
            user.value = userBluePrint;
            isUserHandlerSidebarActive.value = true;
        };


        return {
            fetchUsers,

            // Filter
            avatarText,

            // methods
            handleUserCreate,

            // data
            isBusy,
            isLoading,

            isFetchingData,
            user,
            users,
            isUserHandlerSidebarActive,
            clearUserData,
            addUser,
            removeUser
        };
    },

    mounted()
    {
        // just to make sure the menu bar is shown
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.table-container {
  position: relative;
  width: 100%;
}

.b-table[aria-busy="true"] + .table-spinner {
  /* this assumes that the spinner component has a width and height */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* make sure spinner is over table */
}

.preloader div:before {
    background-color: var(--primary-color) !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
